// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

// Primefaces themes
@import "../node_modules/primereact/resources/primereact.min.css";
@import "../node_modules/primereact/resources/themes/nova-light/theme.css";
@import "../node_modules/primeicons/primeicons.css";

// Fullcalendar
@import "../node_modules/@fullcalendar/core/main.css";
@import "../node_modules/@fullcalendar/daygrid/main.css";
@import "../node_modules/@fullcalendar/timegrid/main.css";
@import "../node_modules/@fullcalendar/list/main.css";

@-webkit-keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUpContent {
	-webkit-animation-name: fadeInUpContent;
	animation-name: fadeInUpContent;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__heading .kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__heading .kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--open>.kt-menu__heading .kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--open>.kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot>span,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover>.kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot>span {
	background-color: #607d8b;
}

.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active>.kt-menu__heading .kt-menu__link-bullet.kt-menu__link-bullet--dot>span,
.kt-aside-dark .kt-aside-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot>span {
	background-color: #607d8b;
}

.btn-primary {
	background-color: #147dbf !important;
	border-color: #147dbf !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	background-color: #36a5eb !important;
	border-color: #36a5eb !important;
	color: #fff !important;
}

.kt-login__title {
	color: #424242 !important;
	font-weight: 600 !important;
}

.kt-login__subtitle {
	font-size: 1.3rem !important;
	color: #616161 !important;
}

.kt-login__copyright {
	color: #424242 !important;
}

.kt-login__menu>a {
	text-decoration: none !important;
	color: #424242 !important;
	text-shadow: 1px 1px 5px #fff !important;
}

.kt-login__menu>a:hover {
	text-decoration: none !important;
	color: #212121 !important;
}

.btn-primary:disabled {
	background-color: #67666e !important;
	border-color: #67666e !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #67666e !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.margin-icon {
	margin-right: 8px;
}

.btn-table-action>i {
	width: 40px !important;
	font-size: 13px !important;
	padding: 0 !important;
}

.btn-table-action {
	margin: 0px 3px 0px 3px;
	height: 40px;
	width: 40px;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.btn-table-action-small>i {
	width: 30px !important;
	font-size: 11px !important;
}

.btn-table-action-small {
	height: 30px;
	width: 30px;
}

.p-datatable-emptymessage {
	height: 100px !important;
	text-align: center !important;
	font-size: 21px !important;
	font-weight: 600 !important;
}

.p-paginator-bottom {
	padding: 10px !important;
	background: #fff !important;
	border: 1px solid #f4f4f4 !important;
	border-bottom-right-radius: 8px !important;
	border-bottom-left-radius: 8px !important;
	text-align: right !important;
}

.p-paginator-current {
	color: #434349 !important;
}

.p-paginator-element.p-link {
	margin: 0px 2px 0px 2px !important;
	border-radius: 5px !important;
}

.p-button {
	margin: 0;
	border-radius: 0.25rem !important;
	border: none !important;
}

.p-datatable .p-datatable-thead>tr>th {
	text-align: left !important;
	background-color: #fff !important;
	border: 1px solid #f4f4f4 !important;
}

.p-datatable .p-datatable-thead>tr {
	height: 50px !important;
}

.p-datatable .p-datatable-tbody>tr>td {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable-header {
	height: 60px;
	background-color: #fff !important;
	border: none !important;
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.p-datatable-loading-overlay.p-component-overlay {
	background-color: #fff !important;
}

.p-datatable-loading-content {
	top: 15% !important;
}

.p-inputtext {
	border: 1px solid #e2e1e1 !important;
}

label.p-dropdown-label.p-inputtext {
	border: none !important;
}

.btn>i.fa-spin {
	padding-right: 0px !important;
	margin-right: 16px !important;
}

.kt-portlet__head-title {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.imagemUploaderBotaoRemover {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.imagemUploaderBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.videoUploaderBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: cover;
}

.imagemUploaderMargem {
	margin-top: 15px;
	margin-bottom: 15px;
}

.fc-day-number {
	color: #424242 !important;
}

.fc-toolbar>*> :not(:first-child) {
	margin-left: 0.4em !important;
}

.fc-button-primary {
	color: #00465d !important;
	background-color: white !important;
	border-color: #00465d !important;
	border-radius: 5px !important;
	height: 40px !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.fc-button-primary:hover {
	color: white !important;
	background-color: #00465d !important;
	border-color: #00465d !important;
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
	color: #fff !important;
	background-color: #00465d !important;
	border-color: #00465d !important;
}

.fc-button-primary:disabled {
	color: #fff !important;
	background-color: #8d39bf !important;
	border-color: #8d39bf !important;
}

.fc-center>h2 {
	color: #333 !important;
}

.fc .fc-row {
	border-right: none !important;
}

.fc th {
	background-color: #fafafa !important;
	border: 1px solid #ddd !important;
	color: inherit !important;
}

a.fc-event:hover {
	color: white !important;
}

.btn-secondary:hover {
	color: #595d6e !important;
}

.btn-app-color {
	border-color: #00465d !important;
	background-color: white !important;
	color: #00465d !important;
}

.btn-app-color:hover {
	border-color: #00465d !important;
	background-color: #00465d !important;
	color: white !important;
}

.MuiInputBase-input.Mui-disabled {
	color: rgba(0, 0, 0, 0.65) !important;
}

.p-datatable-tbody td {
	word-break: break-all;
	white-space: wrap;
	text-overflow: ellipsis;
}

.add-imagem {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 5px;
}

.btn-remover-imagem {
	position: absolute;
	width: 25px;
	height: 25px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 99;
}

.btn-remover-imagem i {
	font-size: 15px !important;
}

.imagem-upload {
	max-height: 300px;
	max-width: 400px;
	min-width: 80px;
	width: 100%;
}

.col-btn-with-input {
	display: flex;
	align-items: flex-end;
	padding-bottom: 8px;
}

.btn-with-input {
	height: 52px;
}

.label-with-texfield {
	margin-bottom: 0px !important;
}

.kt-scrolltop {
	background: #147dbf !important;
}

.kt-link:hover {
	color: #147dbf !important;
}

.p-sortable-column-icon {
	color: #848484 !important;
}

.p-column-title {
	color: #333333 !important;
}

.box-status-ativo {
	border-radius: 4px;
	padding: 4px 8px;
	background-color: #b2e8d4;
	display: inline-block;
}

.box-status-inativo {
	border-radius: 4px;
	padding: 4px 8px;
	background-color: #dfe8f9;
	display: inline-block;
}

.box-status-ativo p,
.box-status-inativo p {
	margin: 0;
	color: #10141a;
	font-size: 14px;
}

/* CUSTOM LINKASH */

.txt-w-600 {
	font-weight: 600;
}

.txt-w-400 {
	font-weight: 400;
}

.txt-40 {
	font-size: 40px;
}

.txt-32 {
	font-size: 32px;
}

.txt-24 {
	font-size: 24px;
}

.txt-16 {
	font-size: 16px;
}

.txt-14 {
	font-size: 14px;
}

.txt-12 {
	font-size: 12px;
}

.color-white {
	color: #FFF
}

.color-gray-900 {
	color: #14181D
}

.color-gray-700 {
	color: #373E46
}

.color-principal-900 {
	color: #082D58
}

.color-principal-100 {
	color: #E7F2FF;
}

.p-button>span {
	font-size: 1rem;
}

.kt-link {
	color: #5A636E !important;
}

.kt-footer__menu-link {
	color: #5A636E !important;
}

.kt-footer__menu-link:hover {
	color: #147dbf !important;
}

.MuiPickersToolbar-toolbar {
	background-color: #147dbf !important;
}

.MuiPickersDay-daySelected {
	background-color: #147dbf !important;
}

.p-button-text-icon-left .p-button-icon-left {
	margin-left: 5px;
}

.p-button.p-button-text-icon-left .p-button-text {
	margin-left: 5px;
}

.kt-login.kt-login--v1 .kt-login__aside {
	width: 50% !important;
}

#copyright-item-mobile {
	display: none;
}

@media (max-width: 1024px) {
	.kt-login.kt-login--v1 .kt-login__aside {
		width: 100% !important;
	}

	#copyright-item {
		display: none;
	}

	#copyright-item-mobile {
		display: flex;
		width: 100%;
		justify-content: center;
	}
}